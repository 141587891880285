(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["react"], factory);
	else if(typeof exports === 'object')
		exports["ReactPaginate"] = factory(require("react"));
	else
		root["ReactPaginate"] = factory(root["React"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__1__) {
return 